import type {
  ListagemOnline,
  ListagemDesconectado,
  ListagemFalhado,
  ListagemStatusLogs,
  ListagemReshare,
  ServidorStatus,
  AxiosResponse,
} from "../../types";
import { painelApi } from "../painelApi";

const PATH_V1_STATUS = "/v1/status";

export async function recuperarOnline(
  idUsuario: string,
  perfilId: string,
): Promise<AxiosResponse<ListagemOnline[]>> {
  const data = {
    idUsuario: idUsuario,
    perfilId: perfilId,
  };

  return await painelApi.post(`${PATH_V1_STATUS}/online`, data);
}

export async function recuperarDesconectado(
  idUsuario: string,
  perfilId: string,
): Promise<AxiosResponse<ListagemDesconectado[]>> {
  const data = {
    idUsuario: idUsuario,
    perfilId: perfilId,
  };

  return await painelApi.post(`${PATH_V1_STATUS}/desconectado`, data);
}

export async function recuperarFalhado(
  idUsuario: string,
  perfilId: string,
): Promise<AxiosResponse<ListagemFalhado[]>> {
  const data = {
    idUsuario: idUsuario,
    perfilId: perfilId,
  };

  return await painelApi.post(`${PATH_V1_STATUS}/falhado`, data);
}

export async function recuperarStatusLogs(
  idUsuario: string,
  perfilId: string,
): Promise<AxiosResponse<ListagemStatusLogs[]>> {
  const data = {
    idUsuario: idUsuario,
    perfilId: perfilId,
  };

  return await painelApi.post(`${PATH_V1_STATUS}/status-logs`, data);
}

export async function recuperarReshare(
  idUsuario: string,
  perfilId: string,
): Promise<AxiosResponse<ListagemReshare[]>> {
  const data = {
    idUsuario: idUsuario,
    perfilId: perfilId,
  };

  return await painelApi.post(`${PATH_V1_STATUS}/status-reshare`, data);
}

export async function recuperarServidorStatus(
  codigo: string,
): Promise<AxiosResponse<ServidorStatus[]>> {
  return await painelApi.get(`${PATH_V1_STATUS}/servidor-status/${codigo}`);
}
