import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { FC, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Layout } from "../../components/layout";
import useAuth from "../../hooks/useAuth";
import { recuperarTodosAlertas } from "../../services/alertaUsuario";
import { recuperarTodosPerfis } from "../../services/perfil";
import {
  recuperarUsuarioPorId,
  salvarUsuario,
  atualizarUsuarioPorId,
} from "../../services/usuario";
import {
  Usuario,
  ListagemAlertaUsuario,
  ListagemPerfil,
  AxiosResponse,
  Response,
} from "../../types";

const TelaEditarUsuario: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user, signout } = useAuth();
  const [alertasUsuario, setAlertasUsuario] =
    useState<ListagemAlertaUsuario[]>();
  const [perfis, setPerfis] = useState<ListagemPerfil[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [usuario, setUsuario] = useState<Usuario>({
    id: null,
    tipoUsuario: "",
    alertaId: "1",
    perfilId: "1",
    cadastradoPor: "",
    usuario: "",
    nome: "",
    senha: "",
    email: "",
    celular: "",
    dataVencimento: "",
    dataNascimento: "",
    conexao: 1,
    valorCobrado: "",
    prePago: "N",
    xml: "S",
    mensagemInterna: "",
    obs: "",
    limiteTeste: "",
    limiteUser: "",
    bloqueado: "N",
  });

  const title = "Editar";

  useEffect(() => {
    if (user?.idUsuario) {
      Promise.all([
        recuperarTodosAlertas()
          .then((response: AxiosResponse<ListagemAlertaUsuario[]>) => {
            if (response) {
              setAlertasUsuario(response.data);
            }
          })
          .catch((error: any) => {
            if (error.response && error.response.status === 401) {
              toast.info("Desconectado por inatividade");
              signout();
              navigate("/");
            } else {
              toast.error("Erro ao recuperar alertas: " + error.message);
            }
          }),
        recuperarTodosPerfis()
          .then((response: AxiosResponse<ListagemPerfil[]>) => {
            if (response) {
              setPerfis(response.data);
            }
          })
          .catch((error: any) => {
            if (error.response && error.response.status === 401) {
              toast.info("Desconectado por inatividade");
              signout();
              navigate("/");
            } else {
              toast.error("Erro ao recuperar perfis: " + error.message);
            }
          }),
      ]);
    }
  }, [user]);

  useEffect(() => {
    if (id) {
      Promise.all([
        recuperarUsuarioPorId(id)
          .then((response: AxiosResponse<Usuario>) => {
            if (response) {
              let fetchedUser = response.data;
              if (
                fetchedUser.tipoUsuario === "ADMIN" ||
                fetchedUser.tipoUsuario === "REVENDA"
              ) {
                fetchedUser = { ...fetchedUser, senha: "" };
              }
              setUsuario(fetchedUser);
            }
          })
          .catch((error: any) => {
            if (error.response && error.response.status === 401) {
              toast.info("Desconectado por inatividade");
              signout();
              navigate("/");
            } else {
              toast.error("Erro ao recuperar o usuário:" + error.message);
            }
          }),
      ]);
    }
  }, [id]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setIsLoading(true);

      if (!user || !user.idUsuario) {
        toast.error(
          "Erro: Usuário não está autenticado ou ID do usuário não disponível.",
        );
        setIsLoading(false);
        return;
      }

      const usuarioParaSalvar = {
        ...usuario,
      };
      if (id) {
        atualizarUsuarioPorId(id, usuarioParaSalvar)
          .then((response: AxiosResponse<Response>) => {
            if (response) {
              toast.success("Usuário atualizado com sucesso!");
              navigate(-1);
            }
          })
          .catch((error) => {
            let errorMessage = "";
            if (
              error.response &&
              error.response.data &&
              error.response.data.response
            ) {
              errorMessage += error.response.data.response;
            } else {
              errorMessage += error.message;
            }
            toast.error(errorMessage);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [usuario],
  );

  const handleCancelar = () => {
    navigate(-1);
  };

  const validarSenha = (valor: string) => {
    const regex = /^\S*$/;
    return regex.test(valor);
  };

  return (
    <Layout>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Card>
          <CardHeader
            subheader="Essas informações podem ser editadas"
            title={title}
          />
          <CardContent sx={{ pt: 0 }}>
            <Box sx={{ m: -1.5 }}>
              <Grid container spacing={3}>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Senha"
                    name="senha"
                    required={true}
                    value={usuario.senha}
                    onChange={(event) => {
                      const valor = event.target.value;
                      if (validarSenha(valor)) {
                        setUsuario({ ...usuario, senha: valor });
                      } else {
                        toast.error("Senha não deve conter espaços.");
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained" disabled={isLoading}>
              {isLoading ? <CircularProgress size={24} /> : "Salvar"}
            </Button>
            <Button
              type="button"
              variant="outlined"
              onClick={handleCancelar}
              disabled={isLoading}
            >
              Cancelar
            </Button>
          </CardActions>
        </Card>
      </form>
    </Layout>
  );
};

export default TelaEditarUsuario;
