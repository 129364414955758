import type { Response, AxiosResponse } from "../../types";
import { painelApi } from "../painelApi";

const PATH_V1_CONFIGURACAO = "/v1/configuracao";

export async function recuperarMensagem(): Promise<AxiosResponse<Response>> {
  return await painelApi.get(`${PATH_V1_CONFIGURACAO}/mensagem`);
}

export async function recuperarMensagemLogin(): Promise<
  AxiosResponse<Response>
> {
  return await painelApi.get(`${PATH_V1_CONFIGURACAO}/mensagemlogin`);
}
