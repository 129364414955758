import { Box, Container, Stack, Typography, TextField } from "@mui/material";
import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Layout } from "../../components/layout";
import { Search } from "../../components/search";
import useAuth from "../../hooks/useAuth";
import { recuperarTodosPerfis } from "../../services/perfil";
import { recuperarStatusLogs } from "../../services/status";
import { ListagemStatusLogs, ListagemPerfil, AxiosResponse } from "../../types";
import { TableStatusLogs } from "./tableStatusLogs";

const HomeStatusLogs: FC = () => {
  const navigate = useNavigate();
  const { user, signout } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [statusLogs, setStatusLogs] = useState<ListagemStatusLogs[]>([]);
  const [perfis, setPerfis] = useState<ListagemPerfil[]>();

  const fetchStatusLogs = async (idUsuario: string, perfilId: string) => {
    try {
      setIsLoading(true);
      const response = await recuperarStatusLogs(idUsuario, perfilId);
      if (response) {
        const dadosComId = response.data.map((item, index) => ({
          ...item,
          id: `id-${index}`,
        }));
        setStatusLogs(dadosComId);
      }
    } catch (error) {
      toast.error("Erro ao recuperar todos os usuários:" + error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPerfis = async (): Promise<ListagemPerfil[] | undefined> => {
    try {
      const response: AxiosResponse<ListagemPerfil[]> =
        await recuperarTodosPerfis();
      if (response && response.data) {
        setPerfis(response.data);
        return response.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        toast.info("Desconectado por inatividade");
        signout();
        navigate("/");
      } else {
        toast.error("Erro ao recuperar perfis: " + error.message);
      }
    }
    return undefined;
  };

  const fetchData = async () => {
    if (user?.idUsuario) {
      const perfisData = await fetchPerfis();
      if (perfisData && perfisData.length > 0) {
        await fetchStatusLogs(user.idUsuario, perfisData[0].id.toString());
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  const handleChangePerfil = (perfil: string) => {
    if (user?.idUsuario) {
      fetchStatusLogs(user.idUsuario, perfil);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = statusLogs.filter((item) =>
    item.nome.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Logs</Typography>
              </Stack>
            </Stack>
            <Box display="flex" alignItems="center" gap={2}>
              <Search onChange={handleSearchChange} />
              <TextField
                label="Perfil"
                name="perfil"
                select
                SelectProps={{ native: true }}
                sx={{ minWidth: 200 }}
                onChange={(event) => handleChangePerfil(event.target.value)}
              >
                <option key={0} value={0}>
                  {"Selecione"}
                </option>
                {perfis?.map((perfil) => (
                  <option key={perfil.id} value={perfil.id}>
                    {perfil.nome}
                  </option>
                ))}
              </TextField>
            </Box>
            <TableStatusLogs items={filteredData} isLoading={isLoading} />
          </Stack>
        </Container>
      </Box>
    </Layout>
  );
};

export default HomeStatusLogs;
