import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import React, { FC } from "react";

interface RenewModalProps {
  isOpen: boolean;
  dateFromToday: string;
  dateFromExpiration: string;
  onConfirm: (fromToday: boolean) => void;
  onCancel: () => void;
}

const RenewModal: FC<RenewModalProps> = ({
  isOpen,
  dateFromToday,
  dateFromExpiration,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <DialogTitle>Renovar Data de Vencimento</DialogTitle>
      <DialogContent>
        Deseja renovar a data de vencimento em 30 dias a partir de hoje ou a
        partir da data atual de vencimento?
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onConfirm(true)} color="primary">
          Hoje + 30 dias ({dateFromToday})
        </Button>
        <Button onClick={() => onConfirm(false)} color="primary" autoFocus>
          Vencimento + 30 dias ({dateFromExpiration})
        </Button>
        <Button onClick={onCancel} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RenewModal;
