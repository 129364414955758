import BoltIcon from "@heroicons/react/24/solid/BoltIcon";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Box,
  Container,
  Stack,
  Typography,
  Button,
  SvgIcon,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import AlterarVencimentoModal from "../../components/alterarVencimentoModal";
import ConfirmDialog from "../../components/confirmDialog";
import { Layout } from "../../components/layout";
import { Search } from "../../components/search";
import { MuiTable } from "../../components/table";
import useAuth from "../../hooks/useAuth";
import {
  recuperarTodosUsuariosHierarquia,
  recuperarTodosUsuariosDiretos,
  deletarUsuarioPorId,
  recuperarHierarquiaPorId,
  atualizarListaDeUsuarios,
  bloquearListaDeUsuariosHierarquia,
} from "../../services/usuario";
import {
  ListagemUsuario,
  AxiosResponse,
  Response,
  HierarquiaUsuario,
} from "../../types";
import "./listagemUsuarioComponent.css";

interface ListagemUsuarioProps {
  tipoUsuario: string;
}

const ListagemUsuarioComponent: FC<ListagemUsuarioProps> = ({
  tipoUsuario,
}) => {
  const navigate = useNavigate();
  const { user, signout, setEditUser } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [confirmBlockOpen, setConfirmBlockOpen] = useState(false);
  const [usuarios, setUsuarios] = useState<ListagemUsuario[]>([]);
  const [hierarquias, setHierarquias] = useState<HierarquiaUsuario[]>([]);
  const [acao, setAcao] = useState<"S" | "N">("S");

  const fetchHierarquia = async (
    userId: string | undefined,
    tipoUsuario: string,
  ) => {
    if (!userId) {
      toast.info("ID do usuário não fornecido para fetchHierarquia.");
      return;
    }

    try {
      const responseHierarquia = await recuperarHierarquiaPorId(
        userId,
        tipoUsuario,
      );
      if (responseHierarquia) {
        setHierarquias(responseHierarquia.data);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
      } else {
        toast.error("Erro ao recuperar hierarquia:" + error);
      }
    }
  };

  const fetchUsuariosHierarquia = async (id: string) => {
    const filtros = {
      cadastradoPorId: id,
      tipoUsuario,
    };

    try {
      const response = await recuperarTodosUsuariosHierarquia(filtros);
      if (response) {
        setUsuarios(response.data);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        toast.info("Desconectado por inatividade");
        signout();
        navigate("/");
      } else {
        toast.error("Erro ao recuperar todos os usuários:" + error);
      }
    }
  };

  const fetchUsuariosDiretos = async (id: string) => {
    const filtros = {
      cadastradoPorId: id,
      tipoUsuario,
    };

    try {
      const response = await recuperarTodosUsuariosDiretos(filtros);
      if (response) {
        setUsuarios(response.data);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        toast.info("Desconectado por inatividade");
        signout();
        navigate("/");
      } else {
        toast.error("Erro ao recuperar todos os usuários:" + error);
      }
    }
  };

  const fetchData = async () => {
    if (user?.idUsuario) {
      await Promise.all([
        fetchUsuariosDiretos(user.idUsuario),
        fetchHierarquia(user.idUsuario, tipoUsuario),
      ]);
    }
  };

  useEffect(() => {
    if (user?.idUsuario) {
      fetchData();
    }
  }, [user]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleAdd = () => {
    const userToEdit = {
      idUsuario: null,
      nome: "",
      dataVencimento: "",
      tipo: tipoUsuario,
    };
    setEditUser(userToEdit);
    navigate(`/usuario/editar`);
  };

  const handleAddRapido = () => {
    const userToEdit = {
      idUsuario: null,
      nome: "",
      dataVencimento: "",
      tipo: tipoUsuario,
    };
    setEditUser(userToEdit);
    navigate(`/usuario/editarRapido`);
  };

  const handleDelete = async (id: string) => {
    try {
      Promise.all([
        deletarUsuarioPorId(id).then((response: AxiosResponse<Response>) => {
          if (response) {
            fetchData();
            toast.success(tipoUsuario + " deletado(a) com sucesso!");
          }
        }),
      ]);
    } catch (error) {
      toast.error("Erro ao deletar usuário: " + error);
    }
  };

  const filteredData = usuarios.filter(
    (item) =>
      item.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.usuario.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleHierarquiaChange = async (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const selectedValue = event.target.value as string;
    const userIdToUse = selectedValue === "0" ? user?.idUsuario : selectedValue;

    if (userIdToUse) {
      await fetchUsuariosHierarquia(userIdToUse);
    } else {
      toast.info("Nenhum ID de usuário disponível para buscar hierarquia.");
    }
  };

  const handleSaveNewExpiry = (newExpiryDate: string) => {
    const alteredUsers: ListagemUsuario[] = [];

    usuarios.forEach((user) => {
      // Garante que ambos, o ID do usuário e os IDs selecionados, sejam tratados como strings
      const userIdAsString = String(user.id).trim();
      const normalizedSelectedIds = selectedIds.map((id) => String(id).trim());

      if (normalizedSelectedIds.includes(userIdAsString)) {
        console.log(`Updating user with ID: ${userIdAsString}`);
        const updatedUser = { ...user, dataVencimento: newExpiryDate };
        alteredUsers.push(updatedUser);
      }
    });

    try {
      Promise.all([
        atualizarListaDeUsuarios(alteredUsers).then(
          (response: AxiosResponse<Response>) => {
            if (response) {
              toast.success(response.data.response);
              fetchData();
              setModalOpen(false);
            }
          },
        ),
      ]);
    } catch (error) {
      toast.error("Erro ao atualizar:" + error);
    }
  };

  const handleSelectedIdsChange = (selectedIdss: string[]) => {
    setSelectedIds(selectedIdss);
  };

  const handleBlockClick = (action: "S" | "N") => {
    setAcao(action);
    setConfirmBlockOpen(true);
  };

  const handleCancelBlock = () => {
    setConfirmBlockOpen(false);
  };

  const handleBloquearHierarquia = async () => {
    const alteredUsers: ListagemUsuario[] = [];

    usuarios.forEach((user) => {
      const userIdAsString = String(user.id).trim();
      const normalizedSelectedIds = selectedIds.map((id) => String(id).trim());

      if (normalizedSelectedIds.includes(userIdAsString)) {
        console.log(`Updating user with ID: ${userIdAsString}`);
        const updatedUser = { ...user, bloqueado: "S" }; // Defina o status como bloqueado
        alteredUsers.push(updatedUser);
      }
    });

    try {
      const response = await bloquearListaDeUsuariosHierarquia(
        alteredUsers,
        acao,
      );
      if (response) {
        toast.success(response.data.response);
        fetchData();
        setConfirmBlockOpen(false);
      }
    } catch (error) {
      toast.error("Erro ao bloquear usuários e hierarquia: " + error);
    }
  };

  const handleActionChange = (event: React.ChangeEvent<any>) => {
    const action = event.target.value as string;

    if (action === "alterarVencimento") {
      setModalOpen(true);
    }

    if (action === "bloquear") {
      handleBlockClick("S");
    }

    if (action === "desbloquear") {
      handleBlockClick("N");
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">
                  {tipoUsuario.charAt(0).toUpperCase() +
                    tipoUsuario.slice(1).toLowerCase()}
                </Typography>
              </Stack>
              <div>
                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <BoltIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  color="success"
                  className="wave-animation"
                  onClick={handleAddRapido}
                >
                  Cadastro Rápido
                </Button>
                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  sx={{ marginLeft: "16px" }}
                  onClick={handleAdd}
                >
                  Cadastro Completo
                </Button>
              </div>
            </Stack>
            <Search
              onChange={handleSearchChange}
              hierarquias={hierarquias}
              onHierarquiaChange={handleHierarquiaChange}
              handleActionChange={handleActionChange}
            />
            <MuiTable
              items={filteredData}
              onDelete={handleDelete}
              onSelectionChange={handleSelectedIdsChange}
            />
          </Stack>
          <AlterarVencimentoModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onSave={handleSaveNewExpiry}
          />
          <ConfirmDialog
            isOpen={confirmBlockOpen}
            title="Confirmar Bloqueio"
            content="Tem certeza que deseja bloquear este usuário e toda a sua hierarquia?"
            onConfirm={handleBloquearHierarquia}
            onCancel={handleCancelBlock}
          />
        </Container>
      </Box>
    </Layout>
  );
};

export default ListagemUsuarioComponent;
